/* eslint-disable camelcase */
import { IconCalendar, IconComment, IconShare, IconVote } from 'components/IconSystem/index';
import {
  convertNumber,
  handleOnListClick, isLessThanOneWeek,
} from 'components/change-log-pc/utils/utils';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Tag from 'components/tag';
import PropTypes from 'prop-types';
import './style.less';
import { dangerouslySetInnerHTML, replaceAnchorHref } from 'components/render-rich-text';
import RichText from 'components/rich-text';
import formatTime from 'components/util/formatTime';
import { href_post, href_profile, hrefTopicDetail } from 'components/href-helper';
import { useThxRoadmapUser } from 'components/hooks/change-log/useThxRoadmapUser';
import _ from 'lodash-es';
import { Avatar } from 'components/user/avatar';
import { PostListSolution } from 'components/post-item/post-list-solution';
import PreviewImage from 'components/preview-image';
import 'components/skeleton/style.less';
import 'components/emoji/emoji-icon.less';

const cls = 'embed-change-log';

export const RenderLinkMobileContext = createContext(null);

const Heading = ({ heading, isNew }) => (
  <Tag.div className={`${cls}-detail-heading`}>
    <Tag.span className={`${cls}-detail-heading__icon`}><IconCalendar /></Tag.span>
    <Tag.span className={`${cls}-detail-heading__title`}>{heading.date}</Tag.span>
    {!!heading.version && <Tag.span className={`${cls}-detail-heading__version`}>ver {heading.version}</Tag.span>}
    {isNew && <Tag.span className={`${cls}__tag ${cls}__tag--right`}>新</Tag.span>}
  </Tag.div>
);

const Author = ({ author, date }) => {
  const ref = useRef(null);

  return (
    <Tag.div className={`${cls}-detail-author`}>
      <Tag.a href={href_profile(author?.id)} target="_blank" rel="noreferrer">
        <Avatar
          src={author?.avatar}
          className={`${cls}-detail-author__avatar`}
        />
      </Tag.a>
      <Tag.a href={href_profile(author?.id)} target="_blank" rel="noreferrer" ref={ref} className={`${cls}-detail-author__name`}>
        <Tag.span>
          {author?.name}
        </Tag.span>
      </Tag.a>
      <Tag.span className={`${cls}-detail-author__date`}>
        发布于 {formatTime(date, 'MM-dd hh:mm')}
      </Tag.span>
    </Tag.div>
  );
};

const BlogItem = ({ item }) => (
  <Tag.div className={`${cls}-detail-item`}>
    <Tag.div className={`${cls}-detail-item__heading`}>{item.title}</Tag.div>
    {
      !!item.detail
      && (
        <PreviewImage content={replaceAnchorHref(item.detail)}>
          <RichText previewImg className={`${cls}-detail-item__content`} content={replaceAnchorHref(item.detail)} />
        </PreviewImage>
      )
    }
  </Tag.div>
);

const Appreciation = ({ students, showLink }) => (
  <Tag.div className={`${cls}-detail-appreciation`}>
    <Tag.div className={`${cls}-detail-appreciation__heading`}>本次更新，感谢以下同学建议</Tag.div>
    <Tag.div className={`${cls}-detail-appreciation__divider`}></Tag.div>
    {
      <Tag.div className={`${cls}-detail-appreciation__students-1`}>
        {
          _.uniqBy(students, 'userId').map((student, index) => (showLink
            ? <Tag.a key={index} href={href_profile(student.userId)} rel="noreferrer" target="_blank" className={`${cls}-detail-appreciation__student-container`}>
              <Avatar
                src={student.avatarUrl}
                className="student-avatar"
              />
              <span className="student-nickname">{student.nickName}</span>
            </Tag.a>
            : <Avatar key={index} src={student.avatarUrl} className="student-avatar" />))
        }
      </Tag.div>
    }
  </Tag.div>
);

const AppreciationWithVote = ({ link, showLink, productId }) => {
  const { isLoading, error, data } = useThxRoadmapUser({
    productId: String(productId),
    topicLink: link,
  });

  const renderContent = () => {
    if (error) {
      return <Tag.div></Tag.div>;
    }
    return (
      (isLoading || !data)
        ? <Tag.div className={`${cls}-detail-appreciation`} style={{ height: '2.52rem' }}>
          <Tag.div className="skeleton-multi" />
          <Tag.div className="skeleton-multi" />
        </Tag.div>
        : <Tag.div className={`${cls}-detail-appreciation`}>
          <Tag.div className={`${cls}-detail-appreciation__heading`}>本次更新，感谢在以下话题中的讨论和建议的每一位</Tag.div>
          <Tag.div className={`${cls}-detail-appreciation__students-1`} style={{ marginTop: '0.2rem' }}>
            {
              _.uniqBy(data.post_users || [], 'user_id').map((student, index) => (showLink
                ? <Tag.a key={index} href={href_profile(student.user_id)} rel="noreferrer" target="_blank">
                  <Avatar
                    src={student.avatar_url}
                    className="student-avatar"
                  />
                </Tag.a>
                : <Avatar key={index} src={student.avatar_url} className="student-avatar" />
              ))
            }
          </Tag.div>
          <Tag.div className={`${cls}-detail-appreciation__divider`}></Tag.div>
          <Tag.div className={`${cls}-detail-appreciation__vote`} onClick={() => hrefTopicDetail(productId, data.id)} role="presentation">
            <Tag.span className={`${cls}-detail-appreciation__vote--hint`}><IconVote /></Tag.span>
            <Tag.span className={`${cls}-detail-appreciation__vote--main`}>{data.title || ''}</Tag.span>
            <Tag.span className={`${cls}-detail-appreciation__vote--hint`}>{convertNumber(data.stat?.content_count || 0)} 讨论</Tag.span>
            <Tag.span className={`${cls}-detail-appreciation__vote--hint`}>{convertNumber(data.stat?.read_count || 0)} 次阅读</Tag.span>
          </Tag.div>
        </Tag.div>
    );
  };

  return renderContent();
};

const Control = ({ postId }) => (
  <Tag.div className={`${cls}-detail-control`}>
    <Tag.a
      className={`${cls}-detail-control__icon ${cls}-detail-control__icon--right`}
      href={href_post(postId)} target="_blank" rel="noreferrer"
    >
      <Tag.div className={`${cls}__icon-img`}>
        <IconComment />
      </Tag.div>
    </Tag.a>
    <Tag.a className={`${cls}-detail-control__icon`} href={href_post(postId)} target="_blank" rel="noreferrer">
      <Tag.div className={`${cls}__icon-img`}>
        <IconShare />
      </Tag.div>
    </Tag.a>
  </Tag.div>
);

const Replies = ({ replies, replyCount, postId }) => {
  const replyValues = Object.values(replies || {});
  const renderLink = useContext(RenderLinkMobileContext);

  return (
    <Tag.div
      className={`${cls}-detail-comment`}
      style={{
        display: !replyCount ? 'none' : 'block',
      }}
    >
      <Tag.div className={`${cls}-detail-comment__list`}>
        {
          replyValues.map((comment, index) => (
            <Tag.div
              className={`${cls}-detail-comment__detail`}
              key={index}
            >
              <Tag.span className={`${cls}-detail-comment__author`}>
                <Tag.a
                  href={href_profile(comment.user_id)}
                  target="_blank" rel="noreferrer"
                  className={`${cls}-detail-comment__link${comment.is_admin ? ` ${cls}-detail-comment__link--admin` : ''}`}
                >
                  {comment.nick_name}
                </Tag.a>
                {comment.parent_reply_id
                  && replies[comment.parent_reply_id]
                  && replies[comment.parent_reply_id].nick_name
                  && replies[comment.parent_reply_id].user_id
                  && <>
                    <Tag.div className={`${cls}__arrow-right ${cls}-detail-comment__author--right`}></Tag.div>
                    <Tag.a
                      href={href_profile(replies[comment.parent_reply_id].user_id)}
                      target="_blank" rel="noreferrer"
                      className={`${cls}-detail-comment__link${replies[comment.parent_reply_id].is_admin ? ` ${cls}-detail-comment__link--admin` : ''}`}
                    >
                      {replies[comment.parent_reply_id].nick_name}
                    </Tag.a>
                  </>
                }
                <Tag.span className={`${cls}-detail-comment__author--right`} isText>:</Tag.span>
              </Tag.span>
              {/* <Tag.a */}
              {/*   className={`${cls}-detail-comment__text ${cls}-detail-comment__link`} */}
              {/*   href={href_post(postId)} */}
              {/*   target="_blank" rel="noreferrer" */}
              {/* > */}
              {/*   {comment.f_reply_text || comment.content} */}
              {/* </Tag.a> */}
              <RichText
                isText
                className={`${cls}-detail-comment__text`}
                content={dangerouslySetInnerHTML(comment.f_reply_text || comment.content, '', 'br', renderLink === 'on' || comment.is_admin).__html}
              />
            </Tag.div>
          ))
        }
        {
          replyCount > 3 && (
            <Tag.div className={`${cls}-detail-comment__more`}>
              <Tag.a className={`${cls}-detail-comment__link`} href={href_post(postId)} target="_blank" rel="noreferrer">
                还有 {replyCount || 0} 条评论
              </Tag.a>
            </Tag.div>
          )
        }
      </Tag.div>
    </Tag.div>
  );
};
export const CommentArea = ({ postInfo }) => (
  <>
    <Control
      postId={postInfo.id}
    />
    <Replies
      replies={postInfo.replies}
      replyCount={postInfo.reply_count}
      postId={postInfo.id}
    />
  </>
);
export const EmbedChangeLog = ({ changeLog, isDetail = false, style = {}, showLink = true, productId }) => {
  const changeLogRef = useRef(null);
  const [showMask, setShowMask] = useState(false);

  useEffect(() => {
    if (!changeLog.isListView || !changeLogRef.current) return;
    setShowMask(changeLogRef.current.offsetHeight >= 275);
  }, [changeLog.isListView]);

  return (
    <Tag.div className={`${cls}-detail`} style={style} ref={changeLogRef}>
      <Heading heading={{
        date: `${isDetail ? '更新日志 ' : ''}${formatTime(changeLog.versionDate, 'yyyy.MM.dd')}`,
        version: changeLog.version,
      }} isNew={isLessThanOneWeek(changeLog.versionDate)}
      />
      {!isDetail && <Author author={changeLog.author} date={changeLog.date} />}
      {
        changeLog.content.map((item, index) => (
          <BlogItem item={item} key={index} />
        ))
      }
      {
        changeLog.thankPostIdList.length > 0
          && <Appreciation students={changeLog.thankPostIdList} showLink={showLink} />
      }

      {
        changeLog.thankVoteIdList.map((item, index) => (
          <AppreciationWithVote link={item} key={index} showLink={showLink} productId={productId} />
        ))
      }

      <Tag.div className={`${cls}-detail__mask`} style={{ display: showMask ? 'block' : 'none' }}></Tag.div>
    </Tag.div>
  );
};

Heading.propTypes = {
  heading: PropTypes.object,
  isNew: PropTypes.bool,
};

Author.propTypes = {
  author: PropTypes.object,
  date: PropTypes.number,
};

BlogItem.propTypes = {
  item: PropTypes.object,
};

Appreciation.propTypes = {
  students: PropTypes.array,
  showLink: PropTypes.bool,
};

EmbedChangeLog.propTypes = {
  changeLog: PropTypes.object,
  isDetail: PropTypes.bool,
  isFirst: PropTypes.bool,
  showLink: PropTypes.bool,
  productId: PropTypes.string,
  style: PropTypes.object,
};

AppreciationWithVote.propTypes = {
  students: PropTypes.array,
  showLink: PropTypes.bool,
  productId: PropTypes.string,
};

Replies.propTypes = {
  replies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  replyCount: PropTypes.number,
  postId: PropTypes.string,
};

Control.propTypes = {
  postId: PropTypes.string,
};

CommentArea.propTypes = {
  postInfo: PropTypes.object,
};
