import React, { Fragment, createRef } from 'react';
import Tag from 'components/tag';
import { createPortal } from 'react-dom';
import { isEmpty } from 'lodash-es';
import { getProductsTagsList, postListTagsAdd, postListTagsDel, postListTagsAllGet } from 'components/api';
import { TcIconTagPcliked, TcIconClosure } from 'components/IconSystem';
import { ariaRole } from 'components/util/aria';
import { Mask } from 'components/mask';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './style.less';

class TagMobileChoose extends React.Component {
  constructor(props) {
    super(props);
    this.cancelBtn = createRef();
    this.prevFocusElm = null;
    this.state = {
      tagArr: [],
      contentArr: {},
    };
  }

  componentDidMount() {
    this.getProductsTagsListApi(this.props.productId);
  }
  componentDidUpdate(prevProps) {
    if (
      !this.props.showtag
      && prevProps.showtag
      && this.prevFocusElm
      && typeof this.prevFocusElm.focus === 'function'
    ) {
      this.prevFocusElm.focus();
    }
  }

  getProductsTagsListApi(productId) {
    getProductsTagsList(productId).then((res) => {
      this.setState({ tagArr: res.data.map(item => ({ ...item, isSet: false })) }, () => {
        this.postListTagsAllGetApi(this.props.productId, 'post', [...this.props.postId]);
      });
    });
  }

  postListTagsAllGetApi(productId, contentType, contentIds) {
    postListTagsAllGet(productId, contentType, contentIds).then((res) => {
      if (!isEmpty(res.data)) {
        this.setState({ contentArr: res.data[contentIds] }, () => {
          this.setDefaultTag();
        });
      }
    });
  }

  setDefaultTag() {
    const { state } = this;
    const tagArr = [...state.tagArr];
    const contentArr = [...state.contentArr.tags];
    tagArr.forEach((e) => {
      contentArr.forEach((t) => {
        if (e.id === t) e.isSet = true;
      });
    });
    this.setState({ tagArr });
  }

  handleChangeTag(itemTag) {
    const { props, state } = this;
    const apiFun = itemTag.isSet ? postListTagsDel : postListTagsAdd;
    apiFun(props.productId, 'post', props.postId, itemTag.id).then((res) => {
      if (res.data) {
        const tagArr = state.tagArr.map(item => (item.id === itemTag.id ? { ...item, isSet: !itemTag.isSet } : item));
        this.setState({ tagArr });
        props.changePostsList(tagArr.filter(i => i.isSet === true), props.postId, props.isTop);
      }
    })
      .catch((error) => {
        props.onError(error.message);
      });
  }

  renderView() {
    const { state, props } = this;
    if (!props.showtag) {
      return null;
    }
    return (
      <Fragment>
        {props.showtag && (
          <Mask onClick={(event) => {
            props.onCancel();
          }}
          />
        )}
        <Tag.div
          {...ariaRole('dialog')}
          aria-modal={true}
          aria-labelledby="opera_desc"
          className={classNames('tags', { active: props.showtag })}
        >
          <Tag.div className="tags_cont">
            <Tag.div className="tags_title">
              <Tag.span>选择标签</Tag.span>
              <TcIconClosure className="tags_title-icon" onClick={(event) => {
                props.onCancel();
              }}
              />
            </Tag.div>
            <Tag.div className="tags_option">
              {
                state.tagArr.map((item, index) => (
                  <Tag.div className={classNames('tags_option-li', { tagActive: item.isSet })} key={index}
                    onClick={() => this.handleChangeTag(item)} tabIndex="-1">
                    <Tag.span style={{ backgroundColor: item.setting.color }} className="tags_option-block"></Tag.span>
                    {item.name}
                    {item.isSet && <TcIconTagPcliked className="tags_option-icon" />}
                  </Tag.div>
                ))
              }
            </Tag.div>
          </Tag.div>
        </Tag.div>
      </Fragment >
    );
  }

  render() {
    return (
      createPortal(
        this.renderView()
        , document.body,
      )
    );
  }
}

TagMobileChoose.propTypes = {
  changePostsList: PropTypes.func,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
  showtag: PropTypes.bool,
  postId: PropTypes.string,
  isTop: PropTypes.bool,
  productId: PropTypes.string,
  tagArrList: PropTypes.array,
};

TagMobileChoose.defaultProps = {
  changePostsList: () => console.log('changePostsList 未绑定'),
  onChange: () => console.log('onChange 未绑定'),
  onError: () => console.log('onError 未绑定'),
  onCancel: () => console.log('onCancel 未绑定'),
};

export { TagMobileChoose };
